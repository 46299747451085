import 'angular:locale/data:bg';
import 'angular:locale/data:ar';
import 'angular:locale/data:cs';
import 'angular:locale/data:fi';
import 'angular:locale/data:da';
import 'angular:locale/data:sv';
import 'angular:locale/data:tr';
import 'angular:locale/data:ko';
import 'angular:locale/data:ru';
import 'angular:locale/data:zh';
import 'angular:locale/data:ja';
import 'angular:locale/data:ro';
import 'angular:locale/data:pt';
import 'angular:locale/data:pl';
import 'angular:locale/data:no';
import 'angular:locale/data:nl';
import 'angular:locale/data:it';
import 'angular:locale/data:hu';
import 'angular:locale/data:fr';
import 'angular:locale/data:es';
import 'angular:locale/data:de';
import 'angular:locale/data:ca';
import 'angular:locale/data:en';
import './src/polyfills.ts';(globalThis.$localize ??= {}).locale = "___NG_LOCALE_INSERT___";
